/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/locomotive-scroll@4.1.4/dist/locomotive-scroll.min.js
 * - /npm/@barba/core@2.9.7/dist/barba.umd.min.js
 * - /npm/bootstrap-datepicker@1.9.0/dist/js/bootstrap-datepicker.min.js
 * - /npm/moment@2.29.4/moment.min.js
 * - /npm/jquery-validation@1.19.3/dist/jquery.validate.min.js
 * - /npm/cleave.js@1.6.0/dist/cleave.min.js
 * - /npm/swiper@7.4.1/swiper-bundle.min.js
 * - /npm/daterangepicker@3.1.0/daterangepicker.min.js
 * - /npm/select2@4.1.0-rc.0/dist/js/select2.min.js
 * - /npm/cleave.js@1.6.0/dist/addons/cleave-phone.nz.js
 * - /npm/gsap@3.10.2/dist/gsap.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
